<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="page">
      <div class="page__hd" style="padding: 15px;">
        <h1 class="page__title">SMI</h1>
        <p class="page__desc">标准时间签名工具</p>
      </div>
      <div class="weui-cells__title">快捷入口</div>
        <div class="weui-cells">
            <router-link to="/time" class="weui-cell  weui-cell_access" href="javascript:">
                <span class="weui-cell__bd">
                    <span>时间签名</span>
                    <div class="weui-cell__desc">标准时间校验（抗干扰）</div>
                </span>
                <span class="weui-cell__ft">
                </span>
            </router-link>
            <router-link to="/filesign" class="weui-cell weui-cell_access" href="javascript:">
                <span class="weui-cell__bd">
                    <span>戴锁记录 - 电子签名水印（开发中）</span>
                    <div class="weui-cell__desc">图片时间校验 / 微信授权校验（开发中）</div>
                </span>
                <span class="weui-cell__ft">
                </span>
            </router-link>
      </div>
      <!-- <div class="page__bd">
          <a href="#button_default" role="button" class="weui-btn weui-btn_default">普通型</a>
          <a href="#button_bottom_fixed" role="button" class="weui-btn weui-btn_default">底部悬浮型</a>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

// export default {
//   name: 'HomeView',
//   components: {
//     HelloWorld
//   }
// }
</script>
