<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="page" style="max-width: 600px;margin: 0 auto;">
    <router-view/>
    <div class="page__bd page__bd_spacing">
      <div class="weui-footer weui-footer_fixed-bottom">
        <!-- <p class="weui-footer__links">
          <a href="javascript:home();" class="weui-footer__link weui-wa-hotarea">WeUI首页</a>
        </p> -->
        <p class="weui-footer__text">Copyright &copy; 2022 YuzeTT</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
